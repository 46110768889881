import React, { useEffect } from 'react';

function App() {
  useEffect(() => {
    // Redirect to hilomarket.com
    window.location.replace("https://www.hilomarket.com");
  }, []);

  return (
    <div className="App">
      <h1>Redirecting...</h1>
    </div>
  );
}

export default App;